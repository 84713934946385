import * as React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
 
  
  const show_descr = post.frontmatter.show_description

  // page start
  return (
    <Layout location={location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article
          className="blog-post container mx-0 px-0 mx-auto min-w-full"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="container">

          <div className="meta">
            <h1 className="text-6xl md:text-7xl font-serif leading-none uppercase text-white text-center" itemProp="headline">{post.frontmatter.title}</h1>
          </div>
        </header>

        
        <section className="gallery flex flex-col md:flex-row flex-nowrap items-center md:overflow-x-scroll w-full h-auto">
              {/* loop gallery starter */}

              
                {/* Post container */}
                {show_descr === true && 
                <div className="p-20">
                  <section
                  dangerouslySetInnerHTML={{ __html: post.html }}
                  itemProp="articleBody"
                  className="bg-white shadow-xl w-96 p-10"          
                />
                </div>
                  }
                  
                  {show_descr !== true && ''}
                
              
            {post.frontmatter.gallery.map(gallery => {  
              const width = gallery.childImageSharp.fluid.presentationWidth  
              const height = gallery.childImageSharp.fluid.presentationHeight 
              const alt = post.frontmatter.alt_images

              return (
                <>
                            
                  {width > height && 
                  <div className="p-20 gallery_image">
                  <Img fluid={gallery.childImageSharp.fluid} className="landscape w-50v md:w-90v shadow-2xl" alt={alt}/></div>
                  }
                  {width < height && 
                  <div className="p-20 gallery_image">
                  <Img fluid={gallery.childImageSharp.fluid} className="portrait w-50v md:w-50v shadow-2xl" alt={alt}/></div>
                  }

                </>

                )
              })}
        </section>
        <div className="hidden md:block font-sans text-xs text-center">← scroll →</div>
    
        <footer className="ml-16 mr-16">
        </footer>
      </article>
      <nav className="blog-post-nav ml-16 mr-16">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate


export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        show_description
        alt_images
        gallery {
          childImageSharp {
            fluid(maxHeight: 2048,) {
              presentationWidth
              presentationHeight
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        coverImage {
          childImageSharp {
            gatsbyImageData(
              width: 2048
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`